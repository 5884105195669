.portfolio {
 max-width: 1300px;
 margin: 0 auto;
 text-shadow: 1px 1px 2px #fff;
 h1 {
  font-weight: 400;
  color: var(--secondary-color);
 }
 h2 {
  font-weight: 300;
  &.h1 {
   font-weight: 400;
   color: var(--secondary-color);
   margin-top: 4rem;
  }
 }
}
