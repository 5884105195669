.header {
 position: -webkit-sticky;
 position: sticky;
 top: 0;
 z-index: 101;
 box-sizing: border-box;
 background-color: var(--default-background);
 .nav-bar {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(
   0.25turn,
   rgba(78, 80, 183, 0),
   rgba(150, 92, 212, 1),
   rgba(78, 80, 183, 0)
  );
  border-image-slice: 1;
  position: relative;
  margin-bottom: 0;
  top: 0;
  transition: none;
  min-height: 60px;
  color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  .header__logo {
   margin: auto;
   margin-left: 5em;
   font-weight: 400;
   font-size: xx-large;
   color: var(--primary-color); /*Fallback*/
   background: -webkit-linear-gradient(
    var(--primary-color),
    var(--secondary-color)
   );
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
  }
  .header__nav {
   margin-left: auto;
   margin-right: auto;
   ul {
    display: flex;
    li {
     list-style-type: none;
     padding: 10px;
    }
   }
  }
  .contact {
   margin: auto;
   margin-right: 5em;
   font-weight: 500;
  }
 }
}

/* Landscape phones and down */
@media (max-width: 480px) {
 .header {
  .nav-bar {
   .header__logo {
    margin: auto;
   }
   .contact {
    margin: auto;
   }
  }
 }
}

// /* Landscape phone to portrait tablet */
// @media (max-width: 767px) { ... }

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
 .header {
  .nav-bar {
   .header__logo {
    margin-left: 2em;
   }
   .contact {
    margin-right: 2em;
   }
  }
 }
}

// /* Large desktop */
// @media (min-width: 1200px) { ... }
