/* Code.module.css */
.parentContainer {
    display: flex;
    gap: 20px;
    /* Adjust spacing between elements */
}

.child {
    flex: 1;
    /* Ensures equal width for child elements */
    border: 1px solid #ccc;
    /* Adjust border style as needed */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    /* Adjust shadow as needed */
    padding: 20px;
    /* Optional: Add padding to child elements */
}