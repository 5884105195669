.App {
 text-align: center;
 background: rgba(245, 245, 245, 0.8);
 height: 100vh;
 section {
  padding-top: 2rem;
  .app-body {
   padding: 3rem 3rem;
  }
 }
}
// /* Landscape phone to portrait tablet */
@media (max-width: 767px) {
 .App {
  section {
   .app-body {
    padding: 0 3rem 3rem 3rem;
   }
  }
 }
}
// /* Landscape phone to portrait tablet */
@media (max-width: 767px) {
 .App {
  height: 125vh;
 }
}
