// Variables
:root {
 --primary-color: #4e50b7;
 --secondary-color: #965cd4;
 --default-text-color: #4e50b7;
 --default-background: #f5f5f5;
 --primary-color-alpha: rgba(78, 80, 183, 0.8);
}

body {
 margin: 0;
 font-family: 'Work Sans', sans-serif;
 font-optical-sizing: auto;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 background: var(--default-background) no-repeat;
 background-image: url(./developer.svg);
 background-size: contain;
 background-position: bottom right;
 height: 100vh;
 font-weight: normal;
 font-style: normal;
 color: var(--default-text-color);
}

.button {
 border: 1px solid var(--default-text-color);
 background-color: transparent;
 height: 1em;
 color: var(--default-text-color);
 cursor: pointer;
 &.is-outlined {
  border-width: 2px;
  border-radius: 9999px;
  padding: 1.2rem;
 }
}

/* Landscape phones and down */
@media (max-width: 480px) {
 body {
  background-size: cover;
  background-position: -8rem 6rem;
 }
}

// /* Landscape phone to portrait tablet */
@media (max-width: 767px) and (min-width: 481px) {
 body {
  background-size: cover;
  background-position: -1rem -4rem;
 }
}
